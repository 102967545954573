* {
  box-sizing: border-box;
}

body {
  margin: 0;

  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: rgb(20,20,20);
}

h1, h2, h3, h4, h5 {
  text-transform: uppercase;
}

h3 {
  font-size: 16px;
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.center-block {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width: 1000px) {
  .center-block {
    padding-left: 20px;
    padding-right: 20px;
  }
}