@keyframes backdrop-in {
    0% {
        background-color: rgba(35, 30, 45, 0);
    }
    100% {
        background-color: rgba(35, 30, 45, .6);
    }
}

.ui-backdrop {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    background-color: rgba(35, 30, 45, 0);
    transition: background-color .4s;
    z-index: 100000;
    padding: 20px;
}

.ui-backdrop--visible {
    pointer-events: all;
    background-color: rgba(35, 30, 45, .6);
    animation: backdrop-in .4s;
}

.ui-backdrop--transparent {
    background-color: rgba(35, 30, 45, 0) !important;
}

.ui-backdrop--disabled {
    pointer-events: none;
}