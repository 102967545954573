.ui-button {
    display: inline-block;
    transition: box-shadow .1s;
    cursor: pointer;
    min-width: 72px;
    padding: 7px 20px 8px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    border: none;
    outline: none;
}

.ui-button:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.ui-button:active {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.ui-button--comapct {
    min-width: 0;
}

.ui-button--disabled {
    background-color: rgb(235, 235, 235) !important;
    color: rgb(84,84,84) !important;
    pointer-events: none;
}

.ui-spinner--button {
    margin-right: 12px;
}