@keyframes image-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes image-bloom {
    0% {
        filter: grayscale(100%);
    }
    100% {
        filter: grayscale(0%);
    }
}

.ui-image {
    position: relative;
}

.ui-image__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ui-image__canvas {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    animation-fill-mode: both;
    animation: image-in 2s, image-bloom 5s;
    z-index: 1;
}