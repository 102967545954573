.ui-tabs {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 48px;
}

.ui-tabs__bar {
  position: absolute;
  bottom: 0;
  height: 48px;
  min-width: 90px;
  transition: width .2s, left .2s;
  z-index: 0;
}