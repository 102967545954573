.ui-input__container {
    position: relative;
}

.ui-input {
    display: flex;
    align-items: center;
    border: 1px solid rgb(175, 175, 175);
    height: 36px;
    flex-grow: 1;
}

.ui-input--margin {
    margin-bottom: 20px;
}

.ui-input__display {
    border: none;
    background-color: transparent;
    flex-grow: 1;
    padding: 0 12px;
    height: 100%;
}

.ui-input__units {
    padding-right: 12px;
    color: rgb(150, 150, 150);
}

.ui-input__label {
    position: absolute;
    background-color: white;
    z-index: 1;
    font-size: 10px;
    left: 8px;
    top: -5px;
    color: rgb(150, 150, 150);
    padding: 0 4px;
}