.ui-tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  min-height: 48px;
  min-width: 90px;
  text-transform: uppercase;
  z-index: 1;
  cursor: pointer;
}