@keyframes ui-card__in {
    0% {
        opacity: 0;
        transform: translateY(16px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

.ui-card {
    display: block;
    background: white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.ui-card--margin {
    margin-bottom: 20px;
}

.ui-card--animate {
    animation-fill-mode: both;
    animation: ui-card__in .4s;
}